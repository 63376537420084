
  export default {
    name: `GenericDialog`,
    data() {
      return {
        toggleDialog: false,
      }
    },
    props: {
      title: {
        type: String,
        default: undefined
      },
      buttonYesTitle: {
        type: String,
        default: undefined
      },
      buttonCancelTitle: {
        type: String,
        default: undefined
      }
    },
    methods: {
      handlerClick() {
        this.toggleDialog = true
      }
    }
  }
